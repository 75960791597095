document.addEventListener('DOMContentLoaded', () => {
  const start = document.querySelector('body.start')
  const remind = document.querySelector('body.remind')
  if (start != null || remind != null) {
    const form = document.getElementById('form')
    const submit = document.querySelector('input[type=submit]')
    if (form && submit) {
      submit.removeAttribute('disabled')
      form.addEventListener('submit', function(event) {
        submit.setAttribute('disabled', true)
      })
    }
  }
})
