// CSS
import 'cozy-bootstrap/dist/cozy-bs.min'
import 'styles/stratus'
import 'styles/onboarding'

// JS
import * as Sentry from '@sentry/browser'
import 'lib/onboarding'
import 'lib/prevent_double_submit'

const sentry_meta = document.querySelector('meta[name=sentry-dsn]')
if (sentry_meta) {
  const sentry_dsn = sentry_meta.content
  Sentry.init({ dsn: sentry_dsn })
}

// On android, change the address bar color to match the page background
document.addEventListener('DOMContentLoaded', () => {
  const paperColor = getComputedStyle(document.body).getPropertyValue(
    '--paperBackgroundColor'
  )
  if (paperColor) {
    const themeColor = document.querySelector('meta[name=theme-color]')
    themeColor.setAttribute('content', paperColor)
  }
})
