const manageEnabled = (field, enabled) => {
  if (enabled) {
    field.removeAttribute('disabled')
  } else {
    field.setAttribute('disabled', 'disabled')
  }
}

const manageClass = (clazz) => (field, value) => {
  if (value) {
    field.classList.add(clazz)
  } else {
    field.classList.remove(clazz)
  }
}

const manageInvalid = manageClass('is-invalid')
const manageInvisible = manageClass('d-none')
const managerDanger = manageClass('text-danger')
const managerMuted = manageClass('text-muted')

const enableInputFields = (form, enabled, ...excluded) => {
  form.querySelectorAll('input:not([type=submit])').forEach((input) => {
    if (excluded.includes(input.name)) return
    manageEnabled(input, enabled)
  })
}

class InputFieldWithErrors {
  constructor(id) {
    this.input_field = document.getElementById(id)
    this.errors_parent = document.getElementById(`${id}-errors`)
    this.errors_children = new Map()
    for (const child of this.errors_parent.querySelectorAll('[data-error]')) {
      this.errors_children.set(child.dataset.error, child)
    }
  }

  get input() {
    return this.input_field
  }

  get value() {
    return this.input.value
  }

  set value(value) {
    this.input.value = value
  }

  checkValidity() {
    return this.input.checkValidity()
  }

  showErrors(errors) {
    const valid = errors.length == 0
    manageInvalid(this.input, !valid)
    for (const [error, child] of this.errors_children) {
      manageInvisible(child, !errors.includes(error))
    }
    manageInvisible(this.errors_parent, valid)
  }

  showError(error) {
    this.showErrors(error == null ? [] : [error])
  }

  clearError() {
    this.showError(null)
  }
}

const triggerThenOn =
  (event) =>
  (field, action, ...args) => {
    field.addEventListener(event, action)
    action(true, ...args)
  }
const triggerThenOnChange = triggerThenOn('change')
const triggerThenOnInput = triggerThenOn('input')

const manageSubmitValidity = (submit_input, checkValidity) => {
  submit_input.form.addEventListener('submit', async (e) => {
    e.preventDefault()
    const valid = await checkValidity()
    if (valid) e.target.submit()
  })
}

const highlightErrors = (tag, codes) => {
  for (const error of tag.querySelectorAll('[data-error]')) {
    const inError = codes.includes(error.dataset.error)
    managerDanger(error, inError)
    managerMuted(error, !inError)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('body.onboarding.email') != null) {
    const submit_input = document.querySelector('input[type=submit]')
    const login_url = submit_input.dataset.existing
    const email_input = new InputFieldWithErrors('email')
    const checkValidity = async (firstCall = false) => {
      let email_exists = false
      let valid = false
      const email = email_input.value
      if (email == '') {
        if (firstCall) {
          email_input.clearError()
          return [false, false]
        } else {
          email_input.showError('invalid')
          return [false, false]
        }
      }

      let code = null
      if ((valid = email.includes('@'))) {
        const response = await fetch(
          `check_email?email=${encodeURIComponent(email)}`,
          // XXX: don't send the cookies as they are not required, and it
          // avoids a race condition in Rails session handling
          { credentials: 'omit' }
        )
        const status = response.status
        switch (status) {
          case 200:
            break
          case 406:
            const json = await response.json()
            let c = json['error']
            if (c == 'used') {
              email_exists = true
            } else {
              ;[valid, code] = [false, c]
            }
            break
          default:
            throw `Unexpected status code ${status}`
        }
      } else {
        code = 'invalid'
      }

      email_input.showError(code)
      return [valid, email_exists]
    }

    triggerThenOnChange(email_input.input, checkValidity)
    submit_input.form.addEventListener('submit', async (e) => {
      e.preventDefault()
      const [valid, exist] = await checkValidity()
      if (!valid) return
      if (exist) {
        window.location = login_url
        return
      }
      e.target.submit()
    })
  }

  if (document.querySelector('body.onboarding.fqdn') != null) {
    const submit_input = document.querySelector('input[type=submit]')
    const slug_input = new InputFieldWithErrors('slug')
    const pattern = slug_input.input.dataset.pattern
    const domain = document.getElementById('domain').textContent
    const restrictions = document.getElementById('restrictions')
    const checkRestriction = () => {
      const fqdn = slug_input.value
      const errors = []
      if (/[^a-z0-9]/.test(fqdn)) errors.push('alphanum')
      if (!/^[a-z]/.test(fqdn)) errors.push('start')
      if (fqdn.length > 30) errors.push('length')
      if (fqdn.includes('@')) errors.push('email')
      highlightErrors(restrictions, errors)
      return errors
    }
    const checkValidity = async () => {
      const fqdn = slug_input.value
      let valid
      const errors = checkRestriction()
      if ((valid = fqdn.match(pattern))) {
        const response = await fetch(`/instances/${fqdn}${domain}`, {
          credentials: 'omit',
        })
        valid = response.status === 404
        if (!valid) errors.push('used')
      } else {
        errors.push('invalid')
      }
      slug_input.showErrors(errors)
      return valid
    }
    triggerThenOnChange(slug_input.input, checkValidity)
    triggerThenOnInput(slug_input.input, checkRestriction)
    manageSubmitValidity(submit_input, checkValidity)
  }

  if (document.querySelector('body.onboarding.terms') != null) {
    const submit_input = document.querySelector('input[type=submit]')
    const terms_input = new InputFieldWithErrors('terms')
    const checkValidity = () => {
      const valid = terms_input.input.checked
      terms_input.showError(valid ? null : 'accept')
      return valid
    }
    manageSubmitValidity(submit_input, checkValidity)
  }

  if (document.querySelector('body.partner.login') != null) {
    const domain_select = document.getElementById('domain_select')
    const slug_input = new InputFieldWithErrors('slug')
    const fqdn_input = document.getElementById('fqdn')
    let default_domain_selected = true
    const default_domain = domain_select.dataset.default

    const calculateFQDN = () => {
      let fqdn = slug_input.value.trim()
      if (fqdn.length === 0) {
        return ''
      }
      if (default_domain_selected && !fqdn.includes('.')) {
        fqdn = `${fqdn}.${default_domain}`
      }
      fqdn_input.value = fqdn
      return fqdn
    }
    domain_select.addEventListener('change', (e) => {
      default_domain_selected = e.target.value === `.${default_domain}`
      domain_select.classList.toggle('shrinked', !default_domain_selected)
      calculateFQDN()
    })

    const submit_input = document.querySelector('input[type=submit]')
    const checkValidity = () => {
      const fqdn = calculateFQDN()
      const error = fqdn.length > 0 ? null : 'invalid'
      slug_input.showError(error)
      return error == null
    }
    slug_input.input.addEventListener('change', checkValidity)
    manageSubmitValidity(submit_input, checkValidity)
  }

  if (document.querySelector('body.partner.remind') != null) {
    const submit_input = document.querySelector('input[type=submit]')
    const email_input = new InputFieldWithErrors('email')
    const checkValidity = () => {
      const email = email_input.value
      const valid = email.includes('@')
      email_input.showError(valid ? null : 'invalid')
      return valid
    }
    email_input.input.addEventListener('change', checkValidity)
    manageSubmitValidity(submit_input, checkValidity)
  }
})
